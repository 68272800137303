import logo from './logo.svg';
import logoWhite from './logo-white.svg';
import psbIcon from './psb.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="top-bar">
          <div className={"container"}>
            <div className={"menu-logo"} style={{ width: '220px' }}>
                <a href="/">
					        <img src={logo} id={"logowhite"} />
					      </a>
            </div>
          </div>
        </div> 
      </header>
      <div className={"page-banner banner_default"} style={{ backgroundImage: `url('https://energybox.wpenginepowered.com/wp-content/uploads/Company-Header-1920-x-330.png')`}}>
        <div className={"container"}>
            <div className={"page-banner-entry"}>
              <h2 className={"text-black"} style={{ textShadow: '0px 0px 40px white' }}>  Leading the Transformation</h2>
            </div>
        </div>
      </div>
      <div className={"container"} style={{ paddingTop: '50px', paddingBottom: '50px', textAlign: 'left' }}>
          <div className={"row"}>
              <div className={"col-lg-12"}>
                  <div className={"large-text"}>
                      <div className={"gmail_default"}>
                        <p>“We are here to make a difference, to improve people’s life and the environment through AI and automation. With the latest in technology, there is a huge opportunity for multi-site enterprises to manage their facilities, equipment and processes better, more efficiently and more sustainably.</p>
                        <p>As a leading IoT automation company, we want to lead this transformation, help our customers simplify their operations and address their daily challenges, while at the same time reducing costs and improving sustainability.</p>
                        <p>Our state-of-the-art IoT Platform provides a turnkey solution and integrates advanced Software, AI, Sensors and Controls. Our solutions are deployed in thousands of facilities, bringing our customers immediate financial return and benefits.<br/>
                        Imagine a world of connected, efficient and autonomous facilities.. everywhere.</p>
                        <p>We are passionate about providing exceptional value to our customers and the Energybox Team is looking forward to working together with you!</p>
                        <p>Enjoy Automation.”</p>
                        <p>Nicolai Wiest</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="section-area section-sp1 bg-white" id="mission">
          <div className="container">
              <div className="row">
                  <div className="col-lg-4 col-md-6 wow fadeInUp m-md-b30" data-wow-delay="0.3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                      <div className="feature-container feature-bx2" style={{ height: '250px', borderRadius: '30px' }}>
                          <div className="icon-content">
                              <h3 className="ttr-tilte">Purpose</h3>
                                  <p>Improve people’s lives and the environment through AI and automation.</p>
                          </div>

                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow fadeInUp m-md-b30" data-wow-delay="0.6s" style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInUp' }}>
                      <div className="feature-container feature-bx2" style={{ height: '250px', borderRadius: '30px' }}>
                          <div className="icon-content">
                              <h3 className="ttr-tilte">Mission</h3>
                                  <p>Automate our customers' sites, equipment, and processes to save energy, reduce costs, and improve sustainability.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-12 wow fadeInUp m-md-b30" data-wow-delay="0.9s" style={{ visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInUp' }}>
                      <div className="feature-container feature-bx2" style={{ height: '250px', borderRadius: '30px' }}>
                          <div className="icon-content">
                              <h3 className="ttr-tilte">Values</h3>
                                  <p>People, transparency, sustainability, simplicity, customer success, innovation.</p>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <footer className="footer-style2">
        <div className="footer-top bt0" style={{ color: '#fff' }}>
            <div className="container">
                <div className="row">
                    <div className="col-3 col-lg-3 col-md-3 col-sm-12">
                        <div className="widget footer_widget text-center text-lg-left">
                          <img id="footer_logo" src={logoWhite} style={{ opacity: '0.85' }} />
                        </div>
                    </div>
                    <div className="col-3 col-lg-3 col-md-3 col-sm-12"></div>
                    <div className="col-3 col-lg-3 col-md-3 col-sm-12"></div>
                    <div className="col-3 col-lg-3 col-md-3 col-sm-12">
                        <p className="m-b0">Copyright Energybox Inc.  </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="row" style={{ display: 'inline-flex' }}>
                    <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
                        <div className="widget footer_widget text-center text-lg-right">
                            <div style={{ margin: '0 auto', padding: '3px 0'}}>
                                <a target={'_blank'} 
                                    href={'https://beian.miit.gov.cn'}
                                    style={{ display: 'inline-block', textDecoration: 'none', height: '20px', lineHeight: '20px' }}
                                >
                                    <p style={{ float: 'left', height: '20px', lineHeight: '20px', margin: '0px 0px 0px 5px', color: '#FFF', fontSize: '1rem' }}>
                                        沪ICP备2021009512号-2
                                    </p>
                                </a>
                                <a target={"_blank"}
                                    href={"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006621"}
                                    style={{ display: 'inline-block', textDecoration: 'none', height: '20px', lineHeight: '20px', marginLeft: '1rem' }}
                                >
                                    <img src={psbIcon} style={{ float: 'left', height: '20px' }}/>
                                    <p style={{ float: 'left', height: '20px', lineHeight: '20px', margin: '0px 0px 0px 5px', color: '#FFF', fontSize: '1rem' }}>
                                        沪公网安备 31010502006621号
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
